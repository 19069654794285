import { HotelType } from '#app/utils/mb/status.ts'
import { Icon } from './ui/icon'

type HotelTypeProps = {
	type: HotelType
	removeIcon?: boolean
}

export function HotelTypeIcon({ type, removeIcon }: HotelTypeProps) {
	if (type === HotelType.RESORT) {
		const label = 'Resort'
		return removeIcon ? label : <Icon name="tree-palm">{label}</Icon>
	}
	if (type === HotelType.GUEST_HOUSE) {
		const label = 'Guest House'
		return removeIcon ? label : <Icon name="house">Guest House</Icon>
	}
	if (type === HotelType.LIVEABOARD) {
		const label = 'Liveaboard'
		return removeIcon ? label : <Icon name="hotel">Liveaboard</Icon>
	}

	const label = 'Hotel'
	return removeIcon ? label : <Icon name="hotel">Hotel</Icon>
}
